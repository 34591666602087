.App{

}

/*----------  Children  ----------*/
.App{
  &__title{
    padding-top: em(30);
    text-align: center;
  }
}

.text-grey{
  align-self: center;
  color: #D5D5D5;
}

.SearchInput{
  margin: em(30) auto em(55) auto;
  max-width: em(600);
  position: relative;
  padding: 0 em(40);
  display: flex;
  position: relative;
  left: em(20);
  // justify-content: center;
  // align-items: center;

  .timezone{
    // display: inline-block;
    // vertical-align: center;
    align-self: center;
    padding-left: em(10);
    a{
      color: #D5D5D5;
      text-decoration: none;
    }
  }

  &:before{
    @include pseudo;
    @include tbma;
    content: "r/";
    width: em(20);
    height: em(20);
    line-height: em(20);
    color: #9c9c9c;
    // left: em(-4);
    left: em(18);
    letter-spacing: 3px;
  }
  input{
    $fz: 14;
    font-size: em($fz);
    padding: em(9, $fz) em(8, $fz);
    width: 100%;
    border: 0;
    border: none;
    border: 1px solid $c-controlGrey;
    border-radius: 3px;
    display: block;
    // height: 40px;
    // -webkit-appearance: textfield;
    -webkit-appearance: none;
    &:invalid {
      box-shadow:none !important;
    }
    &::placeholder{
      letter-spacing: .4px;
      color: $c-lightGrey;
      font-weight: 300;
    }
  }
  button{
    @include resetBtn;
    $fz: 12;
    font-size: em($fz);
    background: $c-lightBlue;
    color: #fff;
    padding: em(9.5, $fz) em(18, $fz);
    border-radius: 100px;
    border-radius: 4px;
    font-family: $ff-content;
    letter-spacing: .4px;
    position: relative;
    margin-left: em(10, $fz);
    color: #fff;
    background: $c-orange;
    text-transform: uppercase;
    font-family: $ff-controls;
    &:active,{
      box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.6);
    }
    &:hover:enabled, &:focus{
      background: darken($c-orange, 8);
    }
  }
}



.Heatmap{
  display: flex;
  justify-content: center;

  @media($toMobile){
    font-size: em(14);
  }

  @media($toDesktop){
    // font-size: em(14);
    float: left;
    .CircularProgress{
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
    }    
  }
}

$h-times: 54;


.Heatmap__left{
  margin-top: 1em;
  margin-top: em($h-times);
  // margin-top: em(54 + 16);
  // width: pc(165, $screen-width);
  width: pc(155, $screen-width);
}

.Heatmap__days{
  padding: em(2) em(10);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #1E2537;
  color: #fff;
  text-align: center;

  h3{
    font-size: em(15);
  }

  @media($fromTablet){
    padding: em(2) 0;
  }
}


.Heatmap__times{
  text-align: center;
  display: flex;
  justify-content: space-around;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(231,231,231,1) 100%);
  border: 1px solid #F3F3F3;
  border-bottom: none;
  height: em($h-times);
  align-items: center;
  div{
    color: #787878;
    font-size: em(14);
  }
}


.Heatmap__cube-container{
  display: flex;
}


.Heatmap__cube{
  width: em(40);
  height: em(40);
  // display: inline-block;
  position: relative;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  span{
    font-size: 14px;
    // display: none;
    font-weight: bold;
  }
  &:hover{
    border: 2px solid rgba(0,0,0,.3);
    span{
      display: inline;
    }
  }
  &:active{
    // box-shadow: inset 0 0 6px rgba(0,0,0,.4);
    // background: rgba(0,0,0,.3);
    border: 4px solid rgba(0,0,0,.3);
  }


}


.tippy-tooltip-content{
  padding: 8px;
}


.Report{
  @media($toDesktop){
    // font-size: em(14);
    float: left;
    padding-left: em(10);
  }

  display: flex;
  justify-content: center;
  margin-top: em(40);
  padding-bottom: em(40);
  a {
    color: $c-lightBlue;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
  .title{
    padding-bottom: em(8);
  }
}


.schedule-button{
  // display: flex;
  // justify-content: center;
  text-align: center;
  padding-bottom: em(30);
  padding-bottom: em(40);
}



.post{
  display: flex;
  align-items: center;
}

.post__item{
  margin-right: em(20);
}