$ff-landing-title: 'Bitter', serif;
$ff-landing-content: 'Montserrat', serif;
$ff-landing-controls: 'Montserrat', sans-serif;

.Landing{
  font-family: $ff-landing-content;

  h1, h2{
   font-family: $ff-landing-title;
  }

  h1{
    font-size: em(38);
  }
  h2{
    font-size: em(22);
  }

  h3{
    font-family: $ff-landing-content;
    font-size: 1em;
  }

  p{
    font-size: em(16);
    padding-top: em(10);
    color: #93918F;
    line-height: 1.8;
  }

  li{
    color: #93918F;
  }
  .features{
    color: #93918F;
  }

  .subreddit-title{
    font-family: $ff-landing-controls;
    color: $c-lightGrey;
    font-size: em(16);
    display: inline-block;
    &:hover{
      text-decoration: underline;
    }
  }


  input{
    $fz: 14;
    font-size: em($fz);
    padding: em(9, $fz) em(8, $fz);
    width: 100%;
    border: 0;
    border: 1px solid $c-controlGrey;
    border-radius: 3px;
    display: block;
    text-align: center;
    &:invalid {
      box-shadow:none !important;
    }
    &::placeholder{
      letter-spacing: .4px;
      color: $c-lightGrey;
      font-weight: 300;
    }
  }




  .text-grey{
    color: #93918F;
  }

  header{
    display: flex;
    justify-content: space-between;
    padding: em(24) pc(31, $site-width) 0;
    @media($toMobile){
      justify-content: center;
    }
  }

  nav{
    color: #636363;
  }
  nav ul{
    display: flex;
    @media($toMobile){
      display: none;
    }
  }
  nav li{
    padding: em(7) em(10) 0;
    font-family: $ff-landing-controls;
    a{    
      font-size: em(15);
      color: inherit;
      color: #636363;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }



  footer{
    display: flex;
    color: $c-lightGrey;    
    margin: 0 auto;
    max-width: pc(1016, $screen-width);
    a{
      color: inherit;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
    img{
      margin-top: -5px;
      filter: grayscale(1); 
      opacity: .6;
    }

    @media($toMobile){
      flex-direction: column-reverse;
      align-items: center;
      margin-top: em(40);
      padding-bottom: em(20);
      a{
        text-decoration: underline;
      }
      div{
        padding: em(30) 0;
      }
      div:first-child{
        padding: em(10);
      }
    }
    @media($fromMobile){
      margin-top: em(150);
      justify-content: space-between;
      align-items: center;
      padding: 0 0 em(30) 0;
    }
  }

  hr{
    opacity: .3;
  }



  .pricing{
    display: flex;
    margin-top: em(45);
    @media($toMobile){
      flex-direction: column;
      margin-top: 0;
    }
    p{
      padding: em(10) 0;
    }
    .features{
      font-size: em(17);
      padding-top: em(15, 17);
      font-weight: bold;
    }
    ul{

    }
    li{
      padding-top: em(10);
    }
  }

  .pricing-element{
    @media($toMobile){
      margin-top: em(70);
    }
    // width: pc(324, $site-width);
    max-width: em(324);
    width: 100%;
    margin-right: pc(20, 324);
    h2{
      font-family: $ff-controls;
      margin-bottom: em(20);
    }
  }

  .Section--page{
    margin: em(50) auto 0;
    max-width: pc($page-width, $site-width);
  }

  .Section--hero{
    margin-top: em(95);
    text-align: center;
    @media($toMobile){
      margin-top: em(40);
    }
  }

  .Section--graphic{
    margin-top: em(100);
    max-width: 90%;
    text-align: center;
    @media($toMobile){
      margin-top: em(60);
    }
  }

  .Section--info{
    margin-top: em(80);
    max-width: pc(720, $screen-width);
    @media($toMobile){
      margin-top: em(10);
      margin-bottom: em(50);
    }
  }
  
}

.Landing__form{
  padding-top: em(30);
}

.Landing__input{
  padding: 0 0 em(20) 0;
  width: em(209);
  margin: 0 auto;
}



.crisp-k30d4h{
  transform: rotate(6deg);
}
#crisp-chatbox > div > a > span{
  background: #FBA53D !important;
}


.Landing{
  .Button{
    font-family: $ff-landing-controls;
    @include resetBtn;
    display: inline-block;
  }

  .Button--hollow{
    // background: #FDB755;
    border: 1px solid #FDB755;
    // padding: em(16.5) em(45);
    padding: em(16.5) em(30);
    // color: #fff;
    color: #060606;
    border-radius: 4px;
    &:hover{
      background: darken(#FDB755, 5);
      color: #fff;
    }
    &:active{
      // http://usabilitypost.com/2012/01/10/pressed-button-state-with-css3/
      box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.6);
    }
    span{
      font-size: em(15);
    }
  }

  .Button--primary{
    background: #FDB755;
    // padding: em(16.5) em(45);
    padding: em(16.5) em(30);
    color: #fff;
    border-radius: 4px;
    &:hover, &:focus{
      background: darken(#FDB755, 5);
    }
    &:active{
      // http://usabilitypost.com/2012/01/10/pressed-button-state-with-css3/
      box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.6);
    }
    span{
      font-size: em(15);
    }
  }

  .Button--cta{
    background: #FDB755;
    letter-spacing: 0;
    margin-top: em(16);
    padding: em(10.5) em(26);
    span{
      font-size: em(15);
    }
  }
}
