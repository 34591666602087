$w: 500;

.Schedule{
  @media($toMobile){
    padding: 0 em(20) em(20);
  }
  .SearchInput{
    left: pc(-6, $site-width);
    max-width: em($w + 64);
    margin-bottom: 0;
  }
}

.Schedule__form{
  max-width: em(382);
  max-width: em($w);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: em(8);
  // padding: 0 em(20);
}

.Schedule__time{
  display: flex;
  justify-content: space-between;
  margin-top: em(20);  
  >div:first-child{
    margin-right: em(10);
  }
  >div{
    width: calc(50%);
  }
}


/*----------  Custom Date Picker  ----------*/
.DateInput{
  border: 1px solid $c-controlGrey;
  border-radius: 3px;
  &:active{
    border-color: none;
  }  
  .DateInput__control{
    min-height: 38px;
  }  
}


.Schedule__post, .Schedule__time{
  label{
    display: inline-block;
    // display: flex;
    margin-bottom: em(10);
  }
}
.schedule-label{
  color: #D5D5D5;
  position: absolute;
  right: -3em;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
}

.Schedule__post{
  margin-top: em(5);
  >div{
    margin-top: em(25);
  }
}

.TimePicker{
  background: #F4F5F7;
  padding: em(3.5) em(13) !important;
  border: 1px solid $c-controlGrey !important;
  border-radius: 3px !important;
  height: 40px;
  >div{
    font-family: $ff-content;
    &:after, &:before{
      display: none;
    }
  }
}