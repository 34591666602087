// Layout
//-----------------------
$screen-width: 1440;
$site-width: 1024;
$page-width: 810;

// Font sizes
//-----------------------
$fz-xsml: 10;
$fz-vsml: 12;
$fz-sml: 14;
$fz-msml: 15;
$fz-normal: 16;
$fz-med: 18;
$fz-lge: 24;
$fz-vlge: 30;
$fz-xlge: 44;




// Colors
//-----------------------
// $c-dark: #3e474c;
// $c-light: #b0babf;
// $c-lighter: #e0e0e0;
// $c-placeholderGrey: #A9A9A9;
// $c-blue: #46bcff;
// $c-green: #5dd39e;
// $c-white: #fff;
// $c-dgrey: #565656;
$c-lightGrey: #AAAAAA;
// $c-mdgrey: #717171;
// $c-mgrey: #8F8F8F;
$c-controlGrey: #e6e6e6;
// $c-darkGrey: #4b5e65;
// $c-dark: #3D3D3D;


// $c-control: #c4c4c4;
// $c-btnDefault: #f2f1f1;

$c-lightBlue: #0087ff;
$c-blue: #0179fe;
$c-orange: #FDB755;

// $c-lightBlue: #55ACFC;
// $c-blue: #0179fe;


// Font family
//-----------------------
// $ff-title: "Proxima Nova", Helvetica, serif;
// $ff-content: "Proxima Nova", Arial, sans-serif;
// $ff-title: 'Helvetica Neue', Helvetica, Arial, sans-serif;
// $ff-content: 'Helvetica Neue', Arial, sans-serif;
// $ff-alt: 'Lato', Arial, sans-serif;

$ff-title: 'Bitter', serif;
$ff-content: "Proxima Nova", 'Helvetica Neue', Helvetica, sans-serif;
$ff-controls: 'Montserrat', sans-serif;
// $ff-content: 'Alegreya', serif;




// Spacing
//-----------------------
$sp-med: 25;
$sp-mlge: 30;
$sp-lge: 50;

/*=====================================
=            Media queries            =
=====================================*/
// $fromMobile: "(min-device-width: 480px)";
// $fromTablet: "(min-device-width: 768px)";
// $fromDesktop: "(min-width: 1024px)";

$toMobile: "max-width: 567px";
$fromMobile: "min-width: 568px";
$toTablet: "max-width: 767px";
$fromTablet: "min-width: 768px";
$toDesktop: "max-width: 1023px";
$fromDesktop: "min-width: 1024px";

/*=====  End of Media queries  ======*/
