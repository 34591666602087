.Button{
  @include resetBtn;
  $fz: 12;
  font-size: em($fz);
  background: $c-lightBlue;
  color: #fff;
  padding: em(9.5, $fz) em(18, $fz);
  border-radius: 100px;
  border-radius: 4px;
  font-family: $ff-content;
  position: relative;
  color: #fff;
  letter-spacing: .4px;
  background: $c-orange;
  font-family: $ff-controls;
  &:active,{
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.6);
  }
  &:hover:enabled, &:focus{
    background: darken($c-orange, 8);
  }
}

.Button--hollow{
  // background: #FDB755;
  border: 1px solid #FDB755;
  // padding: em(16.5) em(45);
  padding: em(16.5) em(30);
  // color: #fff;
  color: #060606;
  border-radius: 4px;
  &:hover{
    background: darken(#FDB755, 5);
    color: #fff;
  }
  &:active{
    // http://usabilitypost.com/2012/01/10/pressed-button-state-with-css3/
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.6);
  }
  span{
    font-size: em(15);
  }
}

.Button--primary{
  background: #FDB755;
  padding: em(16.5) em(30);
}

.Button--payment{
  font-family: "Proxima Nova", Arial, sans-serif;
  @include resetBtn;
  padding: 0.625em 1.5625em;
  text-align: center;
  min-width: 120px;
  border-radius: 2px;
  cursor: pointer;
  background: #ffad3e;
  color: #fff;
}