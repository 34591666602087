// If it has parameters then it's a mixin. 
// If it doesn't make it a %placeholder and @extend it.

@mixin font($font, $fz, $fw:null){
  @if ($fw){
    font: $fw em($fz) $font;
  }
  @else{
    font: em($fz) $font;
  }
}

@mixin wh($w, $h: $w, $fz: 16){
  width: em($w, $fz);
  height: em($h, $fz);
}

@mixin whp($w, $h: $w){
  width: $w * 1%;
  height: $h * 1%;
}

@mixin pseudo{
  content: "";
  position: absolute;
}
@mixin tbma{
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}
@mixin trblma{
  @include tbma;
  left: 0;
  right: 0;
  margin: auto;
}

@mixin clearfix{
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}


/**
 * Vertically center
 */
@mixin vertical-center{
  height: 100%;
  display: flex;
  align-items: center;
}

@mixin vc{
  @include vertical-center;
}

@mixin fromMobile{
  @media (min-width: 768px) { @content; }
}

@mixin fromTablet{
  @media (min-width: 1024px) { @content; }
}


@mixin toMobile{
 @media only screen and (max-width: 767px) { 
   @content; 
 }
}

@mixin toTablet{
  @media (max--width: 767px) { @content; }
}

@mixin toDesktop{
  @media (max-width: 1023px) { @content; }
}


@mixin resetBtn{
  outline: 0;
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
  font-size: inherit;
  text-decoration: none;
  cursor: pointer;
}

@mixin wh100{
  width: 100%;
  height: 100%;
}