body{
  font-family: $ff-content;
  // font-smoothing: antialiased;
  // -webkit-font-smoothing: antialiased;
  // text-rendering: optimizeLegibility;
}

h1, h2{
 font-family: $ff-title;
 // font-smoothing: antialiased;
 // -webkit-font-smoothing: antialiased;
 // text-rendering: optimizeLegibility;
}

.to-mobile{
  @media($fromMobile){
    display: none;
  }
}

.from-mobile{
  @media($toMobile){
    display: none;    
  }
}

.to-desktop{
  display: inherit;
}


.pages{
  max-width: em($page-width);
  position: relative;
  // max-width: 960px;
  margin: 0 auto;
  padding-bottom: em(75);
  padding-top: 20px;
  // @include toMobile{
  //   padding-top: 40px;
  // }
}


.Button--payment{
  font-family: "Proxima Nova", Arial, sans-serif;
  @include resetBtn;
  padding: 0.625em 1.5625em;
  text-align: center;
  min-width: 120px;
  border-radius: 2px;
  cursor: pointer;
  background: #ffad3e;
  color: #fff;
}


.Payment__title{
  font-size: 1.5em;
  font-family: "Proxima Nova", Arial, sans-serif;
  color: #3e474c;
  letter-spacing: 1px;
}

.Payment select{
  text-transform: none;
  color: inherit;
  font: inherit;
  margin: 0;
}




.post{
  &:not(:first-of-type){
    margin-top: em(15);
  }
}