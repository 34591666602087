.Input, .TextArea{
  $fz: 14;
  font-size: em($fz);
  padding: em(9, $fz) em(8, $fz);
  width: 100%;
  border: 0;
  border: none;
  border: 1px solid $c-controlGrey;
  border-radius: 3px;
  display: block;
  border: none;
  border: 1px solid $c-controlGrey;
}

.Input{ 
  // height: 40px;
  // -webkit-appearance: textfield;
  -webkit-appearance: none;
  &:invalid {
    box-shadow:none !important;
  }
  &::placeholder{
    letter-spacing: .4px;
    color: $c-lightGrey;
    font-weight: 300;
  }
  &:before{
    @include pseudo;
    @include tbma;
    content: "r/";
    width: em(20);
    height: em(20);
    line-height: em(20);
    color: #9c9c9c;
    // left: em(-4);
    left: em(18);
    letter-spacing: 3px;
  }
}

.disabled{
  background: #F4F5F7;
}
