@function pc($val, $val2: $val) {
  @return percentage($val / $val2);
}

//Used to convert PX to EMs for multiple properties or values or both.
//$base is an optional measurement that allows making measurements relative to the parent font size rather than the current. Also accepts a list of lists (instead a list of values) for properties that accept multiple lists of values. Only numbers of converted; everything else is passed through
@function pem($pxval, $base: 16) {
  @return #{$pxval / $base}em;
}

@function em($pxval, $base: 16) {
  @return #{$pxval / $base}em;
}